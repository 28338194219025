import React, { useEffect, useState } from 'react'

export const AppContext = React.createContext()

const Provider = props => {
  const [panel, togglePanel] = useState(false)
  const [cartType, setCartType] = useState()

  /** NewsLetter **/
  const [items, setItems] = useState(() => {
    const windowGlobal = typeof window !== 'undefined' && window

    if (windowGlobal) {
      const localData = windowGlobal.localStorage.getItem('newsletter')
      return localData ? JSON.parse(localData) : []
    } else {
      return []
    }
  })

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window

    windowGlobal.localStorage.setItem('newsletter', JSON.stringify(items))
  }, [items])

  const addItem = item => {
    togglePanel(true)
    setItems([...items, item])
  }

  const removeItem = item => {
    const newArr = items.filter(el => el.stockNumber !== item)
    setItems(newArr)
  }

  const updateItem = (index, newObj) => {
    const newItems = [...items]
    newItems[index] = newObj
    setItems(newItems)
  }

  /** Request for Quote/Rentals **/
  const [requestPanel, toggleRequestPanel] = useState(false)
  const [requestItems, setRequestItems] = useState(() => {
    const windowGlobal = typeof window !== 'undefined' && window

    if (windowGlobal) {
      const localData = windowGlobal.localStorage.getItem('request')
      return localData ? JSON.parse(localData) : []
    } else {
      return []
    }
  })

  useEffect(() => {
    if (!requestItems.some(i => i.cartType === 'quote')) {
      setCartType('rental')
    } else if (!requestItems.some(i => i.cartType === 'rental')) {
      setCartType('quote')
    } else {
      setCartType(cartType || 'quote')
    }
  }, [requestPanel])

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window

    windowGlobal.localStorage.setItem('request', JSON.stringify(requestItems))
  }, [requestItems])

  const addRequestItem = requestItem => {
    toggleRequestPanel(true)
    setCartType(requestItem.cartType)
    setRequestItems([requestItem, ...requestItems])
  }
  const handleSetQuantity = (index, newObj) => {
    const newItems = [...requestItems]
    newItems[index] = newObj
    setRequestItems(newItems)
  }
  const removeRequestItem = index => {
    const newArr = requestItems.filter((item, i) => i !== index)
    setRequestItems(newArr)
  }
  const clearCart = type => {
    const itemsRemaining = requestItems.filter(i => i.cartType !== type)
    setRequestItems(itemsRemaining)
  }
  return (
    <AppContext.Provider
      value={{
        items,
        panel,
        requestPanel,
        requestItems,
        cartType,
        addItem: item => addItem(item),
        addRequestItem: item => addRequestItem(item),
        removeItem: item => removeItem(item),
        togglePanel: () => togglePanel(!panel),
        toggleRequestPanel: () => toggleRequestPanel(!requestPanel),
        updateItem: (newObj, index) => updateItem(newObj, index),
        handleSetQuantity: (index, newObj) => handleSetQuantity(index, newObj),
        removeRequestItem: index => removeRequestItem(index),
        clearCart: type => clearCart(type),
        setCartType: type => setCartType(type)
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
